<template>
  <div class="const_width">
    <div class="left_part">
      <div class="content_container mb-0">
        <v-col class="pb-0">
          <v-text-field
              v-model="search"
              label="Takım veya lig adıyla arama yapabilirsiniz..."
              prepend-icon="search"
              color="_c1"
              outlined
              dense
              hide-details
          ></v-text-field>
        </v-col>
        <div class="filter_bar">
          <div class="checkboxes" v-if="this.tr === getMenuItems()">
            <div class="checkbox" @click="live = !live">
              <div>
                <v-icon v-if="live" color="#41a775">check_circle</v-icon>
                <v-icon v-else color="#a2b8bf">radio_button_unchecked</v-icon>
              </div>
              <div>Canlı</div>
            </div>
            <div class="checkbox" @click="iddaa = !iddaa">
              <div>
                <v-icon v-if="iddaa" color="#41a775">check_circle</v-icon>
                <v-icon v-else color="#a2b8bf">radio_button_unchecked</v-icon>
              </div>
              <div>İddaa</div>
            </div>
            <div class="checkbox" @click="iddaaLive = !iddaaLive">
              <div>
                <v-icon v-if="iddaaLive" color="#41a775">check_circle</v-icon>
                <v-icon v-else color="#a2b8bf">radio_button_unchecked</v-icon>
              </div>
              <div>Canlı İddaa</div>
            </div>
          </div>
          <div class="checkboxes" v-if="this.en === getMenuItems()">
            <div class="checkbox" @click="live = !live">
              <div>
                <v-icon v-if="live" color="#41a775">check_circle</v-icon>
                <v-icon v-else color="#a2b8bf">radio_button_unchecked</v-icon>
              </div>
              <div>Live</div>
            </div>
            <div class="checkbox" @click="iddaa = !iddaa">
              <div>
                <v-icon v-if="iddaa" color="#41a775">check_circle</v-icon>
                <v-icon v-else color="#a2b8bf">radio_button_unchecked</v-icon>
              </div>
              <div>Bet</div>
            </div>
            <div class="checkbox" @click="iddaaLive = !iddaaLive">
              <div>
                <v-icon v-if="iddaaLive" color="#41a775">check_circle</v-icon>
                <v-icon v-else color="#a2b8bf">radio_button_unchecked</v-icon>
              </div>
              <div>Live Bet</div>
            </div>
          </div>
          <div class="ml-auto toggle_buttons">
            <div class="toggle_button_small" @click="previousDay()">&lt;</div>
            <v-menu
                ref="menu"
                v-model="datePickerModel"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <div v-on="on" class="toggle_button date_picker_container"
                     v-text="formatPickersDate()"/>
              </template>
              <v-date-picker
                  v-model="currentDate"
                  no-title
                  scrollable
                  locale="tr"
              />
            </v-menu>
            <div class="date_picker"></div>
            <div class="toggle_button_small" @click="nextDay()">&gt;</div>
          </div>
        </div>
        <v-data-table
            :headers="headers"
            :search="search"
            hide-default-header
            :items="filteredMatches"
            sort-by="categoryId"
            no-data-text="Bu kriterlerde maç bulunamadı!"
            :items-per-page="10"
            dense
        >
          <template v-slot:item="{item}">
            <div class="live_scores_match">
              <div class="lsm_fav_icon">
                <v-icon
                    @click="addMatch(item)"
                    :color="isExist(item)? 'error': '_c1'"
                    v-text="isExist(item)? 'remove_circle_outline': 'add_circle_outline'"
                />
              </div>
              <div class="lsm_status">
                <span v-if="item.ended">MS</span>
                <span class="live" v-if="item.live && item.halfTime">İY</span>
                <span class="live" v-if="item.live && !item.halfTime">{{ item.min }}'</span>
                <span v-if="item.status === 'Fixture' && item.status !== 'Postponed'">{{ startTime(item.dateTimeUtc) }}</span>
                <span v-if="item.status === 'Fixture' && item.status === 'Postponed'">Ert.</span>
              </div>
              <div class="lsm_home_team">
                <span @click="toTeamDetail(item.homeTeamId)">{{ item.homeTeam }}</span>
              </div>
              <div class="lsm_score" :class="{'live': item.live}">
                <span @click="toMatchDetail(item)" v-if="item.status !== 'Fixture'">{{ item.score }}</span>
                <span @click="toMatchDetail(item)" v-else>-</span>
              </div>
              <div class="lsm_away_team">
                <span @click="toTeamDetail(item.awayTeamId)">{{ item.awayTeam }}</span>
              </div>
              <div class="lms_half_time">
                <span v-if="item.halfTime">({{ item.hscoreHT }} - {{ item.ascoreHT }})</span>
              </div>
              <div class="lms_options">
                <div>
                  <div v-if="item.iddaaId" class="lms_bet_icon"
                       :class="{'live': item.iddaaLive}"></div>
                </div>
                <div>
                  <div v-if="item.lineups" class="lms_lineups"
                       @click="toMatchLineups(item)"
                  ></div>
                </div>
                <div>
                  <div v-if="item.tv" class="lms_tv tooltip">
                    <span>{{ item.tv }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </v-data-table>
      </div>
      <v-row>
        <MmtCard
            v-for="(match, lmti) in selectedMatches"
            :key="match.id"
            :match="match"
            :class-id="`sr-widget-${lmti}`"
            :match-id="match.id"
            :column="column"
            @remove_lmt="removeCard"
        />
      </v-row>
    </div>
    <div class="right_part">
      <div class="content_container pb-3">
        <h2 class="fv_title mt-0" v-if="this.tr === getMenuItems()">Günün Popüler Maçları</h2>
        <h2 class="fv_title mt-0" v-if="this.en === getMenuItems()">Popular Matches of the Day</h2>
        <div class="match_card"
             @click="addMatch(m)"
             v-for="(m, mi) in sortedAndFilteredMatches"
             :key="mi"
        >
          <div class="match_card_team">
            <img class="match_card_team_logo"
                 :src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/teams/${m.homeTeamId}.png`" alt=""/>
            <div class="match_card_team_name">{{ m.homeTeam }}</div>
          </div>
          <div class="match_card_vs">v</div>
          <div class="match_card_team">
            <img class="match_card_team_logo"
                 :src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/teams/${m.awayTeamId}.png`" alt=""/>
            <div class="match_card_team_name">{{ m.awayTeam }}</div>
          </div>
        </div>
      </div>
    </div>
    <!--<v-row>
        <v-col cols="12" md="6" class="text-right">
            <v-btn-toggle
                    mandatory
                    active-class="_c1 white&#45;&#45;text"
            >
                <v-btn height="40" small @click="column = 4">
                    3 Sütun
                </v-btn>
                <v-btn height="40" small @click="column = 6">
                    2 Sütun
                </v-btn>
            </v-btn-toggle>
        </v-col>
    </v-row>-->
  </div>
</template>

<script>
import MmtCard from "./MmtCard";
import {fetchMultiMatch} from "../../api";
import dayjs from "dayjs";

export default {
  name: "MultiMatchTracking",
  components: {MmtCard},
  data() {
    return {
      matches: null,
      tr: false,
      en: false,
      selectedMatches: [],
      column: 6,
      search: '',
      currentDate: new Date().toISOString().substr(0, 10),
      headers: [
        {text: '', value: 'homeTeam',},
        {text: '', value: 'awayTeam'},
        {text: '', value: 'tournamentName'},
      ],
      live: false,
      iddaa: false,
      iddaaLive: false,
      poll: null,
      datePickerModel: false,
    }
  },
  mounted() {
    this.fetchMatchList();
    this.poll = setInterval(() => {
      this.fetchMatchList();
    }, 20000);
  },
  methods: {
    getMenuItems() {
      if (localStorage.getItem('language') === 'tr') {
        return this.tr = true;
      } else {
        return this.en = true;
      }
    },
    async fetchMatchList() {
      const data = await fetchMultiMatch(this.currentDate);
      this.matches = data.data.data;
    },
    addMatch(match) {
      //const isMatchAlreadyAdded = this.selectedMatches.filter(m => m.id === match.id).length > 0
      const isMatchAlreadyAdded = this.selectedMatches.some(m => m.id === match.id)
      if (isMatchAlreadyAdded) {
        // remove the match from the list
        //this.selectedMatches = this.selectedMatches.filter(m => m.id !== match.id);
        this.selectedMatches = this.selectedMatches.filter(m => {
          return m.id !== match.id
        });
      } else {
        // add the match to the list
        this.selectedMatches.push(match);
      }
    },
    nextDay() {
      this.currentDate = dayjs(this.currentDate).add(1, 'days').format('YYYY-MM-DD');
    },

    previousDay() {
      this.currentDate = dayjs(this.currentDate).subtract(1, 'days').format('YYYY-MM-DD');
    },

    formatPickersDate() {
      return dayjs(this.currentDate).format("YYYY-MM-DD")
    },

    startTime(value) {
      return dayjs(value).format("HH:mm")
    },

    isExist(match) {
      return this.selectedMatches.filter(m => m.id === match.id).length > 0;
    },

    toTeamDetail(id) {
      let routeData = this.$router.resolve({name: 'TeamDetail', params: {id: id}});
      window.open(routeData.href, '_blank');
    },

    toMatchDetail(match) {
      let routeData = {};
      if (match.status !== 'Fixture') {
        routeData = this.$router.resolve({name: 'MDInfo', params: {id: match.id}});
      } else {
        routeData = this.$router.resolve({name: 'MDCompare', params: {id: match.id}});
      }
      window.open(routeData.href, '_blank');
    },
    toMatchLineups(match) {
      const routeData = this.$router.resolve({name: 'MDLineups', params: {id: match.id}});
      window.open(routeData.href, '_blank');
    },

    removeCard(id) {
      this.addMatch({id: id})
    }
  },
  computed: {
    sortedAndFilteredMatches() {
      if (this.matches) {
        let sorted = this.matches;
        sorted = sorted.sort(function (a, b) {
          return a.categoryId - b.categoryId
        });
        if (this.matches.length > 10) {
          let firstTen = [];
          for (let i = 0; i < 10; i++) {
            firstTen.push(sorted[i]);
          }
          return firstTen;
        } else {
          return null
        }
      } else {
        return null
      }
    },
    filteredMatches() {
      let fData = [];
      if (this.matches) {
        let filteredMatches = this.matches;
        if (this.live) {
          filteredMatches = filteredMatches.filter(item => item.live === true);
        }
        if (this.iddaa) {
          filteredMatches = filteredMatches.filter(item => item.iddaaId);
        }
        if (this.iddaaLive) {
          filteredMatches = filteredMatches.filter(item => item.isIddaaLive);
        }
        fData = filteredMatches;
      }
      return fData;
    }
  },
  watch: {
    currentDate() {
      this.matches = [];
      clearInterval(this.poll);
      this.fetchMatchList();
      this.poll = setInterval(() => {
        this.fetchMatchList();
      }, 20000);
    },
  },

  beforeDestroy() {
    clearInterval(this.poll);
  },
}
</script>

<style scoped>
.match_card {
  display: grid;
  grid-template-columns: 45% 10% 45%;
  height: 80px;
  background-color: #edf1ef;
  margin: 10px 10px 0 10px;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none;
}

.match_card_team {
  display: grid;
  grid-template-rows: 50px 20px;
}

.match_card_team_logo {
  width: 60px;
  max-width: 40px;
  height: 60px;
  max-height: 40px;
  display: block;
  margin: 5px auto;
}

.match_card_team_name {
  text-align: center;
  font-size: 12px;
  color: #616161;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.match_card_vs {
  align-self: center;
  text-align: center;
  color: #616161;
  font-size: 20px;
}

.filter_bar {
  border-bottom: 1px solid #edf2f4;
}

.lsm_fav_icon {
  padding-top: 0 !important;
}

.lsm_fav_icon img {
  cursor: pointer;
}

.lsm_status {
  text-align: center;
}

.lsm_score {
  text-align: center;
  font-weight: 800;
}

.lsm_score span {
  cursor: pointer;
}

.lsm_home_team, .lsm_away_team {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lsm_home_team span, .lsm_away_team span {
  cursor: pointer;
}

.lsm_home_team .cards {
  margin-left: auto;
}

.lms_half_time, .lms_corner {
  text-align: center;
  color: #bbbbbb;
}

.lms_bet_icon {
  background-image: url("../../assets/icons/icon-iddaa-green.svg");
  background-position: 3px 4px;
  background-size: 16px;
}

.lms_bet_icon.live {
  background-image: url("../../assets/icons/icon-iddaa-red.svg");
}

.lms_lineups {
  background-image: url("../../assets/icons/icon-lineups.svg");
  background-position: 3px 4px;
  background-size: 16px;
  cursor: pointer;
}

.lms_tv {
  background-image: url("../../assets/icons/icon-tv.svg");
  background-position: 3px 4px;
  background-size: 16px;
}

.live_scores_match {
  display: grid;
  grid-template-columns: 50px 60px minmax(220px, auto) 50px minmax(220px, auto) 40px 72px;
  grid-template-rows: 23px;
  line-height: 23px;
  padding: 0 10px;
  border-bottom: 1px solid #edf2f4;
}

.lsm_home_team {
  text-align: right;
}

.lms_options {
  display: grid;
  grid-template-columns: 24px 24px 24px;
}

.lms_options div {
  width: 24px;
  height: 23px;
}

.tooltip span {
  margin-top: 0;
  top: 20px;
  right: 20px;
}

@media only screen and (max-width: 650px) {
  .live_scores_match {
    display: grid;
    grid-template-columns: 30px 40px minmax(100px, auto) 50px minmax(100px, auto);
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
  }

  .lms_half_time, .lms_bet_icon, .lms_options {
    display: none;
  }
}

@media only screen and (max-width: 426px) {
  .live_scores_match {
    display: grid;
    grid-template-columns: 30px 40px minmax(100px, auto) 50px minmax(100px, auto);
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
  }

  .lms_half_time, .lms_bet_icon, .lms_options {
    display: none;
  }
}

@media only screen and (max-width: 376px) {
  .live_scores_match {
    display: grid;
    grid-template-columns: 30px 40px minmax(100px, auto) 50px minmax(100px, auto);
    grid-template-rows: 23px;
    line-height: 23px;
    padding: 0 10px;
    border-bottom: 1px solid #edf2f4;
  }

  .lms_half_time, .lms_bet_icon, .lms_options {
    display: none;
  }
}
</style>
