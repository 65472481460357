<template>
  <v-col cols="12" md="6">
    <div class="mmt-card-bar _c1">
      <div class="teams-info">
        {{match.homeTeam}} {{match.score}} {{match.awayTeam}}
      </div>
      <v-btn icon dark small @click="close">
        <v-icon>clear</v-icon>
      </v-btn>
    </div>

    <FVLmtV2 :with-scoreboard="true" :match-id="matchId"></FVLmtV2>
  </v-col>
</template>

<script>
//const FvLmt = () => import('@/components/fv-lmt/FvLmt');

const FVLmtV2 = () => import('@/components/fv-lmt-v2/views/FVLmtV2')

export default {
  name: "MmtCard",
  components: {FVLmtV2},
  props: ['matchId', 'classId', 'column','match'],

  methods: {
    close() {
      this.$emit('remove_lmt', this.matchId)
    }
  },
}
</script>

<style scoped>
.teams-info {
  margin-left: 15px;
  font-weight: 500;
}

.mmt-card-bar {
  display: flex;
  place-items: center;
  justify-content: space-between;
}
</style>
